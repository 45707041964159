import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useClick } from "../../hooks/useClick";
import NumberSign from "../../components/NumberSign";
import { EnergyProgress } from "../../components/EnergyProgress";
import { ScoreCounter } from "../../components/ScoreCounter";
import { useUser } from "../../hooks/useUser";
import { ClickerSkeleton } from "./ClickerSkeleton";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { SheetDailyReward } from "../../components/SheetDailyReward";
import Draggable from "react-draggable";
import { SheetRanking } from "../../components/SheetRanking";
import { useWebSocket } from "../../hooks/useWebsocket";
import { toast } from "react-toastify";
import { SheetCommucationReward } from "../../components/SheetCommucationReward";

export const Clicker: React.FC = () => {
  const { handleClick, clickCount, energyCount } = useClick();
  const imgRef = useRef<HTMLImageElement>(null);

  const [numberSignPositions, setNumberSignPositions] = useState<
    { x: number; y: number; id: number; value: number }[]
  >([]);
  const [numberSignId, setNumberSignId] = useState<number>(0);
  const [activeTouches, setActiveTouches] = useState<Set<number>>(new Set());
  const [imageClicked, setImageClicked] = useState<boolean>(false);
  const { user } = useUser();
  const isSkeletonLoading = false;

  const countValue = () => {
    console.log("countValue", user?.lastTimeUltimate);

    let tapPerTime = user?.tapPerTime || 1;

    let critValue = user?.critValue || 1;
    let critPercent = user?.critPercent || 0;

    if (
      user?.expireTimeCritical100 &&
      user?.expireTimeCritical100 > Date.now()
    ) {
      critValue = 100;
      critPercent = 20;
    }

    if (critPercent) {
      if (critPercent > Math.random() * 100) {
        tapPerTime = critValue * (user?.tapPerTime || 1);
      }
    }

    if (user?.expireTimeX2Coin && user?.expireTimeX2Coin > Date.now()) {
      tapPerTime = tapPerTime * 2;
    }

    if (
      user?.lastTimeUltimate &&
      user?.lastTimeUltimate + 24 * 1000 * 60 * 60 > Date.now()
    ) {
      return tapPerTime * user.energy;
    }

    return tapPerTime;
  };

  const countEnergy = () => {
    if (
      user?.lastTimeUltimate &&
      user?.lastTimeUltimate + 24 * 1000 * 60 * 60 > Date.now()
    ) {
      return user.energy;
    }
    return 1;
  };

  const handleTouchStart = (event: TouchEvent) => {
    event?.preventDefault();
    const newTouches = new Set<number>(
      Array.from(event.touches, (touch) => touch.identifier)
    );
    const newTouchIdentifiers = Array.from(newTouches).filter(
      (identifier) => !activeTouches.has(identifier)
    );

    if (newTouchIdentifiers.length > 0) {
      const touchPositions = Array.from(event.touches)
        .filter((touch) => newTouchIdentifiers.includes(touch.identifier))
        .map((touch, index) => ({
          x: touch.clientX,
          y: touch.clientY,
          id: numberSignId + index + 1,
          timestamp: Date.now(),
          value: countValue(),
        }));

      if (energyCount > 0) {
        setNumberSignId((prevId) => prevId + touchPositions.length);

        setImageClicked(true);
        setTimeout(() => setImageClicked(false), 100);

        setNumberSignPositions((prevPositions) => [
          ...prevPositions,
          ...touchPositions,
        ]);
      }

      touchPositions.forEach((position) => {
        if (user) {
          handleClick({
            user_id: user.tgId,
            position: { x: position.x, y: position.y },
            time_stamp: Date.now(),
            value: countValue(),
            energy: countEnergy(),
          });
        }
      });

      setActiveTouches(newTouches);
    }
  };

  const handleTouchMove = (event: TouchEvent) => {
    event.preventDefault();
  };

  const handleTouchEnd = (event: TouchEvent) => {
    event.preventDefault();
    const remainingTouches = new Set<number>(
      Array.from(event.touches, (touch) => touch.identifier)
    );
    setActiveTouches(remainingTouches);
  };

  const handleAnimationEnd = (id: number) => {
    setNumberSignPositions((prevPositions) =>
      prevPositions.filter((position) => position.id !== id)
    );
  };

  const handleContextMenu = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent default context menu
  };

  const handleClickEvent = () => {
    if (user) {
      handleClick({
        user_id: user.tgId,
        position: { x: 100, y: 200 },
        time_stamp: Date.now(),
        value: countValue(),
        energy: countEnergy(),
      });
    }
  };
  if (isSkeletonLoading)
    return (
      <div onScroll={(e) => e.preventDefault()}>
        <ClickerSkeleton />
      </div>
    );
  return (
    <div onScroll={(e) => e.preventDefault()} style={{ height: "100%" }}>
      <Header />
      <Logo>
        <img src="/img/logo_dark.svg" />
      </Logo>
      <WrapMint>
        <WrapMintInner>
          <ScoreCounter clickCount={clickCount} />
          <ImageToucher>
            <img
              ref={imgRef}
              src="./img/captian.png"
              //@ts-ignore
              onTouchStart={handleTouchStart}
              //@ts-ignore
              onTouchMove={handleTouchMove}
              //@ts-ignore
              onTouchEnd={handleTouchEnd}
              //@ts-ignore
              onTouchCancel={handleTouchEnd}
              onClick={handleClickEvent}
              onContextMenu={handleContextMenu}
              className={` 
                ${imageClicked ? "onTap" : ""}`}
              alt="Tap"
            />
            {numberSignPositions.map((position) => (
              <NumberSign
                key={position.id}
                x={position.x}
                y={position.y}
                value={position.value}
                id={position.id}
                onAnimationEnd={handleAnimationEnd}
              />
            ))}
          </ImageToucher>

          <EnergyProgress
            energyCount={Math.min(energyCount, user?.maxEnergy || 1000)}
            maxEnergyCount={user?.maxEnergy || 1000}
          />
        </WrapMintInner>
        <Actions />
        <CheckReward />
      </WrapMint>
      <div style={{ position: "fixed", top: 0, left: 0, zIndex: 102 }}>
        <Ranking />
      </div>
    </div>
  );
};

const CheckReward = () => {
  const [showReward, setShowReward] = useState<boolean>(false);
  return (
    <div>
      <CheckRewardStyled
        onClick={() => {
          setShowReward(true);
        }}
      >
        <img src="/img/chest1.svg" />
        Check reward
      </CheckRewardStyled>
      <SheetCommucationReward
        isOpen={showReward}
        onClose={() => {
          setShowReward(false);
        }}
      />
    </div>
  );
};

const CheckRewardStyled = styled.div`
  background: #282b30;
  margin: 16px;
  padding: 8px;
  border-radius: 12px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
    height: 24px;
  }
`;

const Ranking = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const trackPos = (data: any) => {
    setPosition({
      x: Math.max(0, Math.min(window.innerWidth - 60, data.x)),
      y: Math.max(0, Math.min(window.innerHeight - 152, data.y)),
    });
  };
  useLayoutEffect(() => {
    const savedPosition = localStorage.getItem("rankingPosition");

    if (savedPosition) {
      setPosition(JSON.parse(savedPosition));
    } else {
      setPosition({ x: window.innerWidth - 100, y: 400 });
    }
  }, []);

  const [showRanking, setShowRanking] = useState<boolean>(false);
  const isDragging = useRef(false);

  useEffect(() => {
    localStorage.setItem("rankingPosition", JSON.stringify(position));
  }, [position]);

  const { user } = useUser();
  const { webSocket } = useWebSocket();
  return (
    <>
      <Draggable
        position={position}
        onStop={() => {
          isDragging.current = false;
        }}
        onDrag={(e, data) => {
          isDragging.current = true;
          trackPos(data);
        }}
        onMouseDown={() => {
          setTimeout(() => {
            if (isDragging.current) return;
            setShowRanking(true);
          }, 100);
        }}
      >
        <div>
          <RankStyled.Wrap>
            <RankStyled.Text>{user?.ranking}</RankStyled.Text>
          </RankStyled.Wrap>
        </div>
      </Draggable>

      <SheetRanking
        isShow={showRanking}
        onClose={() => setShowRanking(false)}
      />
      <RankStyled.Refresh
        style={{
          zIndex: 102,
          left: position.x + 20,
          top: position.y + 60,
          width: 26,
          height: 26,
        }}
        onClick={() => {
          toast.success("Refreshed ranking...");
          webSocket?.emit("getUser", user?.tgId);
        }}
      >
        <img src="/img/refresh2.svg" />
      </RankStyled.Refresh>
    </>
  );
};

const RankStyled = {
  Refresh: styled.div`
    position: absolute;
    bottom: -30px;
    color: #fff;
    left: 17px;
  `,
  Wrap: styled.div`
    height: 52px;
    width: 60px;
    background: url("/img/rank.png");
    background-size: contain;
    padding: 22px 0;
    text-align: center;
  `,
  Text: styled.div`
    font-family: Quantico;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    text-shadow: 0px 0.5px 0px #00000099;
  `,
};

const Logo = styled.div`
  margin-bottom: 16px;
  img {
    display: block;
    margin: 0 auto;
  }
`;

const WrapMintInner = styled.div`
  background-image: url("./img/bg.png");
  background-size: cover;
  background-position: center;
  height: calc(100% - 150px);
`;

const WrapMint = styled.div`
  background: linear-gradient(180deg, #0b191e 0%, #0b0e15 100%);
  border-radius: 50px 50px 0 0;
  box-shadow: 0px -2px 4px 0px #8515de, 0px -16px 30px 0px #0b167666;
  padding-top: 16px;
  padding-bottom: 60px;
  height: calc(100% - 180px);
  overflow: auto;
`;

const ImageToucher = styled.div`
  text-align: center;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: inline-block;

    max-width: 100%;
    max-height: 100%;

    &.onTap {
      transition: transform 0.1s;
      transform: scale(0.99);
    }
  }
`;

const Actions = () => {
  const navigate = useNavigate();

  const [isDaily, setIsDaily] = useState<boolean>(false);
  return (
    <>
      <Styled.Wrap>
        <Styled.Item
          onClick={() => {
            setIsDaily(true);
          }}
        >
          <Styled.Icon>
            <img src="/img/daily_reward.png" />
          </Styled.Icon>
          <Styled.Text>Daily reward</Styled.Text>
        </Styled.Item>
        <Styled.Item onClick={() => navigate("/friend")}>
          <Styled.Icon>
            <img src="/img/invite_friends.png" />
          </Styled.Icon>
          <Styled.Text>Invite friends</Styled.Text>
        </Styled.Item>

        <Styled.Item onClick={() => navigate("/mine")}>
          <Styled.Icon>
            <img src="/img/special_booster.png" />
          </Styled.Icon>
          <Styled.Text>Special booster</Styled.Text>
        </Styled.Item>
      </Styled.Wrap>

      <SheetDailyReward isOpen={isDaily} onClose={() => setIsDaily(false)} />
    </>
  );
};

const Styled = {
  Wrap: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
    padding-bottom: 0;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    height: 70px;
    flex: 1;
    background: #282b30;
    box-shadow: 0px -4px 0px 0px #6161fb;
    border-radius: 12px;
    border: 0.3px solid #787878;
    padding-bottom: 16px;
  `,
  Icon: styled.div`
    margin-bottom: 8px;
    margin-top: -30px;
    img {
      height: 50px;
    }
  `,
  Text: styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #bd54ff;
  `,
  Time: styled.div`
    font-weight: 400;
    color: #8e8e8e;
    font-size: 10px;
  `,
};
