import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
const { adminApiUrl } = getConfig();

export const AddDiamond = () => {
  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState({
    name: "",
    category: "",
    icon: "",
    type: "x2_coin",
    description: "",
    price: 0,
    maxBuyPerWeek: 0,
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchProduct = async () => {
        const response = await fetch(
          `${adminApiUrl}/admin/products-diamond/${id}`
        );
        const data = await response.json();
        setData(data);
      };
      fetchProduct();
    }
  }, [id]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!data.name) {
      toast.error("Please fill all fields");
      return;
    }
    if (id) {
      await fetch(`${adminApiUrl}/admin/products-diamond/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      navigate(-1);
      return;
    }

    const response = await fetch(`${adminApiUrl}/admin/products-diamond`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    });

    if (response.ok) {
      setIsError(false);
      setIsSuccess(true);

      setData({
        name: "",
        category: "",
        type: "mine",
        icon: "",
        description: "",
        price: 0,
        maxBuyPerWeek: 0,
      });
    } else {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <Styled.Wrap>
      <Styled.Label>Product type</Styled.Label>
      <select
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.type}
        onChange={(e) => setData({ ...data, type: e.target.value })}
      >
        <option value="">Select type</option>
        <option value="x2_coin">X2 coin</option>
        <option value="100_crit">100 crit</option>
        <option value="12h_off">12h Offline</option>
       
        {/* <option value="auto_tap">Auto tap</option> */}
        <option value="energy_x1">Energy x1</option>
        <option value="energy_x3">Energy x3</option>
        <option value="ultimate">Ultimate</option>


        <option value="offline_30"> Offline 30%</option>
        <option value="offline_50"> Offline 50%</option>
        <option value="offline_100"> Offline 100%</option>

        
        {/* <option value="airdrop">Airdrop</option> */}
      </select>
      <Styled.Label>Product name</Styled.Label>
      <input
        type="text"
        placeholder="Product name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <Styled.Label>Price</Styled.Label>
      <input
        type="number"
        placeholder="Product name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.price}
        onChange={(e) => setData({ ...data, price: parseInt(e.target.value)  || 0})}
      />
      <Styled.Label>Max buy per week</Styled.Label>
      <input
        type="number"
        placeholder="Product name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.maxBuyPerWeek}
        onChange={(e) =>
          setData({ ...data, maxBuyPerWeek: parseInt(e.target.value)  || 0})
        }
      />

      {/* <Styled.Label>From</Styled.Label>
      <DateTimePicker
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(value) =>
          setData({ ...data, from: dayjs(value).format("YYYY-MM-DD hh:mm") })
        }
        value={data.from}
      />

      <Styled.Label>To</Styled.Label>
      <DateTimePicker
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.to}
        onChange={(value) =>
          setData({ ...data, to: dayjs(value).format("YYYY-MM-DD hh:mm") })
        }
      /> */}

      <Styled.Label>Description</Styled.Label>
      <textarea
        placeholder="Description"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.description}
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />

      <br />

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Input: styled.input`
    background-color: #222;
    padding: 1rem 4rem;
    padding: 10px;
    width: 100%;
    outline: none;
  `,
  Label: styled.label`
    display: block;
    padding: 10px;
    font-size: 12px;
  `,
  Row: styled.div`
    display: flex;
    gap: 2px;
  `,
  RowItem: styled.div`
    flex: 2;
    &:first-child {
      flex: 1;
    }
  `,
};
