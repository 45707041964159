import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
  useRoutes,
} from "react-router-dom";
import { GrSign, GrUserAdd, GrNorton, GrDeploy } from "react-icons/gr";
import { useWebSocket } from "../../hooks/useWebsocket";
import Loader from "../../components/Loader/Loader";
import { usePageLoading } from "../../hooks/usePageLoading";
import { useEffect, useMemo, useRef, useState } from "react";
import { getPlatform } from "../../services/telegramService";
import QRCode from "react-qr-code";
import { getConfig } from "../../utils/config";
import QRCodeComponent from "../../components/QrCodeComponent";
import styled from "styled-components";

import { useUser } from "../../hooks/useUser";

export const Layout = () => {
  const { isPageLoading } = usePageLoading();
  const platform = getPlatform();

  const isLocalhost = window.location?.hostname === "localhost";

  const { user } = useUser();
  const timer = useRef<any>(null);
  const { webSocket } = useWebSocket();
  useEffect(() => {
    if (webSocket && user) {
      timer.current = setInterval(() => {
        webSocket.emit("updateLastLogin", user?.tgId);
      }, 60 * 1000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [webSocket, user]);

  if (platform !== "ios" && platform !== "android" &&  !isLocalhost) {
    return <QRCodeComponent />;
  }

  if (isPageLoading) {
    return <Loader />;
  }
  return (
    <WrapStyled>
      <MainStyled>
        <Outlet />
      </MainStyled>
      <BottomActions />
    </WrapStyled>
  );
};
const WrapStyled = styled.div`
  height: 100%;
  background-color: #010101;
`;

const MainStyled = styled.div`
  // padding-top: 144px;
  height: 100%;
  // padding-bottom: 100px;
  overflow: auto;
`;

const BottomActions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menus = [
    {
      text: "Mint",
      icon: <img src="/img/ic_mine.svg" alt="coin" />,
      iconActive: <img src="/img/ic_mine.svg" alt="coin" />,
      link: "/",
    },
    {
      text: "Invest",
      icon: <img src="/img/ic_invest.svg" alt="coin" />,
      iconActive: <img src="/img/ic_invest_active.svg" alt="coin" />,
      link: "/mine",
    },
    {
      text: "Friends",
      icon: <img src="/img/ic_friend.svg" alt="coin" />,
      iconActive: <img src="/img/ic_friend_active.svg" alt="coin" />,
      link: "/friend",
    },
    {
      text: "Earn",
      icon: <img src="/img/ic_earn.svg" alt="coin" />,
      iconActive: <img src="/img/ic_earn_active.svg" alt="coin" />,
      link: "/tasks",
    },
    // {
    //   text: "Airdrop",
    //   icon: <AirdropIcon />,
    //   link: "/airdrop",
    // },
  ];
  return (
    <BottomStyled.Wrap>
      {menus.map((menu, index) => (
        <BottomStyled.Item
          key={index}
          className={location?.pathname === menu.link ? "active" : ""}
          onClick={() => {
            navigate(menu.link || "/");
          }}
        >
          <BottomStyled.ItemIcon>
            {location?.pathname === menu.link ? menu.iconActive : menu.icon}
          </BottomStyled.ItemIcon>
          <BottomStyled.ItemText>{menu.text}</BottomStyled.ItemText>
        </BottomStyled.Item>
      ))}
    </BottomStyled.Wrap>
  );
};

const BottomStyled = {
  Wrap: styled.div`
    z-index: 100;
    height: 51px;
    background: linear-gradient(180deg, #282b30 0%, #3b4047 100%);
    border-radius: 16px;

    position: fixed;
    bottom: 100px;
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    left: 8px;
    bottom: 8px;
    right: 8px;
    padding: 4px;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 4px;
    flex: 1;
    border-radius: 16px;
    height: 43px;

    p {
      color: #777777;
      font-size: 10px;
    }

    &.active {
      background: #13161a;
      p {
        color: #ffffff;
        font-weight: 600;
      }
    }
  `,
  ItemIcon: styled.div`
    img {
      height: 20px;
    }
  `,
  ItemText: styled.p`
    font-size: 10px;
    color: #777777;
  `,
};
