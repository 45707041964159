import React, { useEffect, useRef } from "react";
import { useUser } from "../../hooks/useUser";
import { ReferralLink } from "../../components/ReferralLink";
import styled from "styled-components";
import { Header } from "../../components/Header";
import { SheetAddMine } from "../../components/SheetAddMine";
import { getConfig } from "../../utils/config";
import { useWebSocket } from "../../hooks/useWebsocket";
import { formatMoney, formatMoneyDecimal } from "../../utils/common";
import dayjs from "dayjs";
import { SheetAddDiamond } from "../../components/SheetAddDiamond";
import { toast } from "react-toastify";
import { SheetAddDiamondOffline } from "../../components/SheetAddDiamondOffline";
import { SheetBuyDiamond } from "../../components/SheetBuyDiamond";

export const Mine = () => {
  const { user, setUser } = useUser();
  console.log("user", user);
  const [isBuyDiamond, setIsBuyDiamond] = React.useState(false);
  const { adminApiUrl } = getConfig();

  const [activeItem, setActiveItem] = React.useState<any>(null);
  const [activeItemDiamond, setActiveItemDiamond] = React.useState<any>(null);
  const [activeItemDiamonds, setActiveItemDiamonds] = React.useState<any>([]);

  const [categories, setCategories] = React.useState<any[]>([]);
  const [tabActive, setTabActive] = React.useState(0);

  React.useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch(adminApiUrl + "/all-categories");
      const data = await response.json();
      setCategories(data);
      setTabActive(data[0].id);
    };
    fetchCategories();
  }, []);

  const [products, setProducts] = React.useState<any[]>([]);
  const [dinamondProducts, setDinamondProducts] = React.useState<any[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(adminApiUrl + "/all-products");
      const data = await response.json();
      setProducts(data);
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(adminApiUrl + "/all-diamond");
      const data = await response.json();
      // setProducts(data);
      setDinamondProducts(data);
    };
    fetchProducts();
  }, []);

  const items = products.filter((item) => item.category == tabActive);
  const { webSocket } = useWebSocket();

  // const product

  const nextSunday = dayjs().day(0).add(7, "day").format("YYYY-MM-DD 12:00");

  useEffect(() => {
    if (webSocket) {
      webSocket.on("buyProductDiamondSuccess", (message: string) => {
        toast.success(message);
      });
      webSocket.on("buyProductResult", (data: any) => {
        toast.error(data);
      });
    }
    return () => {
      webSocket?.off("buyProductResult");
      webSocket?.off("buyProductDiamondSuccess");
    };
  }, [webSocket]);

  const timeOffline = (user?.timeOffline || 0) / 1000;
  const countDateOffline = {
    day: Math.floor(timeOffline / 86400),
    hour: Math.floor((timeOffline % 86400) / 3600),
    minute: Math.floor((timeOffline % 3600) / 60),
  };

  var countDateOfflineString = "";

  if (countDateOffline.day > 0) {
    countDateOfflineString += `${countDateOffline.day} days `;
  }
  if (countDateOffline.hour > 0) {
    countDateOfflineString += `${countDateOffline.hour} hours `;
  }
  if (countDateOffline.minute > 0) {
    countDateOfflineString += `${countDateOffline.minute} minutes `;
  }

  // console.log(user);

  return (
    <div onScroll={(e) => e.preventDefault()} style={{ height: "100%" }}>
      <Header />

      <Styled.Content>
        <Styled.Time>
          <img src="/img/info.svg" />
          <CountDownTime lastTime={nextSunday} />
        </Styled.Time>
        <Styled.Combo>
          <Styled.Boxs>
            {dinamondProducts
              .filter(
                (a) =>
                  a.type != "offline_50" &&
                  a.type != "offline_100" &&
                  a.type != "offline_30"
              )
              .map((item) => {
                const haveItem = (user?.diamondProducts || []).find(
                  (a) => a.mode == item.type
                );
                const disabled =
                  haveItem || item.price > (user?.diamondBalance || 0);
                return (
                  <Styled.Box
                    key={item.id}
                    onClick={() => {
                      if( item.price > (user?.diamondBalance || 0)){
                        toast.error("Not enough diamond");
                        setIsBuyDiamond(true);
                        return;
                      }
                      if (disabled) {
                        // setIsBuyDiamond(true);
                        return;
                      }
                      setActiveItemDiamond(item);
                    }}
                  >
                    <Styled.ImgBg>
                      <Styled.Img>
                        {item.type == "x2_coin" && (
                          <img src="/img/x2coin.png" />
                        )}
                        {item.type == "100_crit" && (
                          <img src="/img/critx100.png" />
                        )}
                        {item.type == "12h_off" && (
                          <img src="/img/off12h.png" />
                        )}
                        {/* {item.type == "auto_tap" && (
                          <img src="/img/autotap.png" />
                        )} */}
                        {item.type == "energy_x1" && (
                          <img src="/img/energyx1.png" />
                        )}
                        {item.type == "energy_x3" && (
                          <img src="/img/energyx3.png" />
                        )}
                        {item.type == "ultimate" && (
                          <img src="/img/ultimate.png" />
                        )}

                        <span>{item.name}</span>
                      </Styled.Img>
                    </Styled.ImgBg>
                    <Styled.ButtonBuy>
                      <img src="/img/dinamod.svg" />
                      {item.price}
                    </Styled.ButtonBuy>
                  </Styled.Box>
                );
              })}
          </Styled.Boxs>
          {timeOffline > 1 ? (
            <Styled.OfflineBg>
              <Styled.Offline>
                <img src="/img/offline.png" />
                <Styled.OfflineTitle>
                  <p>Get {formatMoney(user?.totalOfflineCanGet)} gold now</p>
                  <p>Your offline {countDateOfflineString}</p>
                </Styled.OfflineTitle>
                <Styled.OfflineBuy
                  onClick={() => {
                    setActiveItemDiamonds(
                      dinamondProducts.filter(
                        (a) =>
                          a.type == "offline_100" ||
                          a.type == "offline_50" ||
                          a.type == "offline_30"
                      )
                    );
                  }}
                >
                  Get Now
                </Styled.OfflineBuy>
              </Styled.Offline>
            </Styled.OfflineBg>
          ) : (
            ""
          )}
        </Styled.Combo>
        <Styled.Tabs>
          {categories.map((category, index) => (
            <Styled.Tab
              onClick={() => {
                setTabActive(category.id);
              }}
              key={category.id}
              className={tabActive == category.id ? "active" : ""}
            >
              <span>{category.name}</span>
            </Styled.Tab>
          ))}
        </Styled.Tabs>

        <Styled.List>
          {items.map((item) => {
            const userLevel = (user?.products || []).find(
              (a) => a.product == item.id
            );

            const nextLevelPrice = Math.round(
              item.basePrice *
                Math.pow(
                  1 + item.pricePercentIncrease * 0.01,
                  userLevel?.level || 0
                )
            );

            const nextLevelProfit = Math.round(
              item.baseProfit *
                Math.pow(
                  1 + item.profitPercentIncrease * 0.01,
                  userLevel?.level || 0
                )
            );
            let nextLevel = (userLevel?.level || 0) + 1;
            let locked = item.rankAllow < (user?.rank || 0);

            if (nextLevel > item.maxLevel) {
              // return null;
              nextLevel = item.maxLevel;
              locked = true;
            }

            return (
              <Styled.Item.Wrap
                className={locked ? "locked" : ""}
                key={item.id}
                onClick={() => {
                  if (locked) {
                    return;
                  }
                  setActiveItem({
                    ...item,
                    nextLevel,
                    nextLevelPrice,
                    nextLevelProfit,
                  });
                }}
              >
                <Styled.Item.Tag>lvl {nextLevel}</Styled.Item.Tag>
                <Styled.Item.Main>
                  <Styled.Item.Icon>
                    <img src={item.icon} />
                  </Styled.Item.Icon>
                  <Styled.Item.Content>
                    <Styled.Item.Title>{item.name}</Styled.Item.Title>
                    <Styled.Item.Desc>Profit per hour</Styled.Item.Desc>
                    <Styled.Item.Profit>
                      <img src="/img/coin.svg" />+{nextLevelProfit}
                    </Styled.Item.Profit>
                  </Styled.Item.Content>
                </Styled.Item.Main>

                <Styled.Item.Footer>
                  <span>Upgrade</span>
                  <img src="/img/coin.svg" />
                  {formatMoneyDecimal(nextLevelPrice)}
                </Styled.Item.Footer>
              </Styled.Item.Wrap>
            );
          })}
        </Styled.List>
      </Styled.Content>

      <SheetBuyDiamond
        isShow={isBuyDiamond}
        onClose={() => {
          setIsBuyDiamond(false);
        }}
        onDone={() => {
          setIsBuyDiamond(false);
        }}
      />

      <SheetAddDiamondOffline
        items={activeItemDiamonds}
        onClose={() => setActiveItemDiamonds([])}
        onDone={(e: any) => {
          if (webSocket) {
            webSocket.emit("buyProductDiamond", {
              tgUserId: user?.tgId,
              productId: e.id,
            });
          }

          setActiveItemDiamonds([]);
        }}
      />

      <SheetAddDiamond
        item={activeItemDiamond}
        onClose={() => setActiveItemDiamond(null)}
        onDone={() => {
          if (webSocket) {
            webSocket.emit("buyProductDiamond", {
              tgUserId: user?.tgId,
              productId: activeItemDiamond.id,
            });
          }

          setActiveItemDiamond(null);
        }}
      />

      <SheetAddMine
        item={activeItem}
        onClose={() => setActiveItem(null)}
        onDone={() => {
          if (webSocket) {
            webSocket.emit("buyProduct", {
              tgUserId: user?.tgId,
              productId: activeItem.id,
            });
          }

          setActiveItem(null);
        }}
      />
    </div>
  );
};

const CountDownTime = ({ lastTime }: { lastTime: string }) => {
  const [time, setTime] = React.useState(0);

  const ref = useRef<any>();

  useEffect(() => {
    ref.current = setInterval(() => {
      setTime(dayjs(lastTime).diff(dayjs(), "second"));
    }, 1000);
    return () => {
      clearInterval(ref.current);
    };
  }, [lastTime]);

  let formatStr = `${Math.floor(time / 3600)
    .toString()
    .padStart(2, "0")}:${Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, "0")}:${(time % 60).toString().padStart(2, "0")}`;

  if (time > 3600 * 24) {
    formatStr = `${Math.floor(time / 3600 / 24)} days`;
  }
  return <span>{formatStr}</span>;
};

const Styled = {
  OfflineBg: styled.div`
    background: linear-gradient(60deg, #fbe261 0%, #b15aff 100%);
    border-radius: 8px;
    padding: 1px;
    height: 58px;
    margin: 8px;
    margin-bottom: 16px;
  `,
  Offline: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    background: linear-gradient(0deg, #282b30 0%, #13161a 100%);
    border-radius: 8px;

    height: 56px;
    padding: 4px 0 4px 8px;
    & > img {
      height: 42px;
    }
  `,
  OfflineTitle: styled.div`
    flex: 1;
    p {
      font-size: 12px;
      color: white;
      margin: 0;
      &:first-child {
        font-weight: 700;
      }
    }
  `,
  OfflineBuy: styled.div`
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 50px;
    margin: 2px;
    font-size: 14px;
    font-weight: 500;
  `,
  Content: styled.div`
    min-height: calc(100% - 144px);
    padding: 0 16px;
    padding-bottom: 100px;

    background: linear-gradient(180deg, #0b191e 0%, #0b0e15 100%);
    border-radius: 50px 50px 0 0;
    box-shadow: 0px -2px 4px 0px #8515de, 0px -16px 30px 0px #0b167666;
  `,
  Time: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 16px;
    img {
      height: 16px;
    }
    span {
      font-size: 14px;
      line-height: 14px;
      color: #8e8e8e;
    }
  `,

  Combo: styled.div`
    // padding: 16px;
    border-radius: 16px;

    background-image: linear-gradient(180deg, #282b30 0%, #282b3000 100%);
  `,
  Title: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    p {
      font-size: 14px;
      line-height: 14px;
      color: white;
      font-weight: 600;
      margin: 0;
    }
  `,
  Buy: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    padding: 0 16px;

    border-radius: 8px;
    gap: 4px;
    font-size: 16px;
    color: white;
    font-weight: 500;
  `,
  Boxs: styled.div`
    display: flex;

    gap: 8px;
    // justify-content: center;
    margin-bottom: 16px;
    overflow-x: auto;
    padding: 16px;
  `,
  Box: styled.div`
    // padding: 8px;
    // background: #32353a;
    // border-radius: 16px;
  `,

  ImgBg: styled.div`
    background: linear-gradient(60deg, #fbe261 0%, #b15aff 100%);
    border-radius: 16px;
    padding: 1px;
    margin-bottom: 8px;
    width: 100px;
    height: 102px;
  `,
  Img: styled.div`
    background: linear-gradient(0deg, #282b30 0%, #13161a 100%);
    border-radius: 16px;
    // padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    img {
      height: 72px;
    }
    span {
      font-size: 10px;
      color: white;
      background: #282b30;
      padding: 2px 4px;
      border-radius: 4px;
    }
  `,

  ButtonBuy: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px;
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    border-radius: 16px;
    height: 24px;
    font-size: 14px;
    font-family: Quantico;
    font-weight: 600;
    color: white;
    img {
      height: 20px;
    }

    &.disabled {
      opacity: 0.3;
    }
  `,

  Coin: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px;
    font-size: 30px;
    font-weight: 600;
    color: white;
    justify-content: center;
    img {
      height: 30px;
    }
  `,
  Tabs: styled.div`
    display: flex;
    gap: 16px;
    border-bottom: 0.5px solid #777777;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  Tab: styled.div`
    font-size: 16px;
    line-height: 16px;
    color: #777777;
    height: 32px;
    position: relative;
    &.active {
      color: white;
      span {
        background: -webkit-linear-gradient(#6161fb 0%, #b15aff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
      }
    }
  `,

  List: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  `,

  Item: {
    Wrap: styled.div`
      background: #282b30;
      border-radius: 16px;
      padding-bottom: 16px;
      overflow: hidden;
      position: relative;

      &.locked {
        opacity: 0.4;
      }
    `,
    Main: styled.div`
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
    `,
    Icon: styled.div`
      width: 48px;
      img {
        width: 48px;
      }
    `,
    Title: styled.div`
      color: white;
      font-size: 12px;
    `,
    Desc: styled.div`
      font-size: 8px;
      color: #777777;
    `,
    Profit: styled.div`
      font-size: 12px;
      display: flex;
      align-items: center;
      img {
        height: 12px;
      }
      color: #777777;
    `,

    Content: styled.div`
      flex: 1;
    `,
    Tag: styled.div`
      background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
      position: absolute;
      width: 100px;
      text-align: center;
      color: white;
      font-size: 14px;
      transform: rotate(45deg);
      right: -28px;
      top: 11px;
    `,
    Footer: styled.div`
      display: flex;
      gap: 4px;
      align-items: center;
      background: #32353a;
      padding: 4px 16px;

      color: white;
      font-size: 14px;

      span {
        font-size: 10px;
        flex: 1;
      }
      img {
        height: 16px;
      }
    `,
  },
};
