import styled from "styled-components";
import { formatMoney, formatMoneyDecimal } from "../utils/common";
import { useEffect } from "react";
import { useWebSocket } from "../hooks/useWebsocket";
import { toast } from "react-toastify";
import { Styled } from "./SheetAddDiamond";

interface IProps {
  items: any[];
  onClose: Function;
  onDone: Function;
}

export const SheetAddDiamondOffline = ({ items, onClose, onDone }: IProps) => {
  const { webSocket } = useWebSocket();

  useEffect(() => {
    if (webSocket) {
      webSocket.on("buyProductDiamondResult", (data: any) => {
        toast.error(data);
      });
      return () => {
        webSocket.off("buyProductDiamondResult");
      };
    }
  }, [webSocket]);

  return (
    <>
      {items.length && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={items.length ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Icon>
            <img src="/img/offline.png" />
          </Styled.Icon>
          <Styled.Title>
            When user open the app. Auto click will enable to consumption enery
            earn point
          </Styled.Title>
          <Styled.Desc>
            Boosting COIN Efficiency
            <br />
            Users can use $SPIN to purchase Special Boosters like
          </Styled.Desc>

          <Row>
            {items.map((item, index) => (
              <Styled.Coin key={index}>
                <img src="/img/dinamod.svg" height={24} />
                <span>{formatMoney(item?.price)}</span>
              </Styled.Coin>
            ))}
          </Row>

          <Styled.Buttons>
            {items.map((item, index) => (
              <Styled.Button onClick={() => onDone(item)}>
                {item.type == "offline_30" ? "30%" : ""}
                {item.type == "offline_50" ? "50%" : ""}
                {item.type == "offline_100" ? "100%" : ""}
              </Styled.Button>
            ))}
          </Styled.Buttons>
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  > * {
    margin: 0;
    flex: 1;
  }
`;
